import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-624bb7bf"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "digital-tit"
};
const _hoisted_2 = {
  key: 0,
  class: "digital-author"
};
const _hoisted_3 = {
  class: "author-name"
};
const _hoisted_4 = {
  key: 0,
  class: "author-owner"
};
const _hoisted_5 = {
  key: 1,
  class: "digital-btn"
};
const _hoisted_6 = {
  key: 2,
  class: "digital-btn"
};
const _hoisted_7 = {
  class: "btn gray"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Error = _resolveComponent("Error");
  const _component_CompFile = _resolveComponent("CompFile");
  const _component_CompTech = _resolveComponent("CompTech");
  return $data.showError ? (_openBlock(), _createBlock(_component_Error, {
    key: 0,
    message: $data.message
  }, null, 8, ["message"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass({
      'digital-box': true,
      'digital-box-pad': $options.ifOwner || $data.op_type
    })
  }, [_createVNode(_component_CompFile, {
    detail: $data.detail,
    file: $data.file,
    ifOwner: $options.ifOwner
  }, null, 8, ["detail", "file", "ifOwner"]), _withDirectives(_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, _toDisplayString($data.detail.title), 1), $data.owner ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: "author-icon",
    style: _normalizeStyle(`background-image:url('${$data.owner.icon ? $data.owner.icon : require('@assets/images/placeholder/default_head.png')}')`)
  }, null, 4), _createElementVNode("div", _hoisted_3, _toDisplayString($data.owner.nickname), 1), $options.ifOwner ? (_openBlock(), _createElementBlock("div", _hoisted_4, "拥有者")) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createVNode(_component_CompTech, {
    detail: $data.detail
  }, null, 8, ["detail"]), $data.button_info.status == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.goToGive && $options.goToGive(...args))
  }, "打开摩点APP操作")])) : $data.button_info.status == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($data.button_info.status_name), 1)])) : _createCommentVNode("", true)], 512), [[_vShow, $data.isShow]])], 2));
}