import CompModel from "./model.vue";
import { ImagePreview } from 'vant';
export default {
  props: {
    detail: {
      type: Object
    },
    file: {
      type: Object
    },
    ifOwner: {
      type: Boolean
    }
  },
  components: {
    CompModel
  },
  data() {
    return {
      showIcon: true,
      seq: 10,
      amount: 100,
      showPlayIcon: true,
      videoUrl: ''
    };
  },
  mounted() {
    const _this = this;
    // 如果是视频类型时
    if (this.detail.nft_type == 3 && _this.file.video) {
      _this.videoUrl = _this.file.video.url;
      _this.video = _this.$refs.video;
      _this.video.addEventListener('play', () => {
        this.showPlayIcon = false;
      });
    }
  },
  unmounted() {
    if (this.detail.nft_type == 3) {
      this.video.removeEventListener('play', () => {
        this.showPlayIcon = true;
      });
    }
  },
  methods: {
    showBig() {
      if (this.detail.nft_type == 1) {
        ImagePreview([this.file.img]);
      } else if (this.detail.nft_type == 2) {
        console.log(111);
      }
    },
    // 商品合成
    goToInfo() {
      location.href = this.detail.merge_info && this.detail.merge_info.url ? this.detail.merge_info.url : '';
    },
    showBigVideo() {
      this.$router.push({
        path: "/project/common/video",
        query: {
          src: this.videoUrl,
          img_url: this.detail.img_url
        }
      });
    },
    // 视频播放
    videoPlay() {
      this.video.play();
    }
  }
};