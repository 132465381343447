import MDRouter from "@/service/router";
import { throttle } from "@/utils/tools";
import Error from "@@/common/Error.vue";
import domain from '@/service/domain';
import { getNtfDeatil, authCheck, ntfReceive } from "@api/index";
import CompTech from "@@/digital/detail/tech.vue";
import CompFile from "@@/digital/detail/file.vue";
import { mapState } from 'vuex';
import { env } from 'md-base-tools/env';
import { showDialog } from 'vant';
import { mdToast } from "../../../utils/tools";
export default {
  components: {
    Error,
    CompTech,
    CompFile
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifOwner() {
      return this.userId && this.owner && this.owner.id && this.userId == this.owner.id ? true : false;
    },
    ifWxApp() {
      return env.isWxApp();
    }
  },
  data() {
    return {
      isShow: true,
      detail: {},
      owner: {},
      file: {},
      showError: true,
      message: "",
      op_type: 0,
      stock_hash: '',
      receive_code: '',
      status: '',
      status_name: '',
      button_info: {},
      //按钮详情
      stock_hash_copy: ''
    };
  },
  async mounted() {
    const stock_hash = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    this.stock_hash = stock_hash;
    await this.getDetail();
    document.title = this.detail.title || "藏品详情";
    await this.$nextTick;
    // 获取linkme链接
    const config = {
      type: 'nft_detail',
      stock_hash: stock_hash
    };
    this.$store.dispatch('currentlLinkUrlAction', config);
  },
  methods: {
    async getDetail(type) {
      const {
        data,
        status,
        message
      } = await getNtfDeatil({
        stock_hash: type && type == 'again' ? this.stock_hash_copy : this.stock_hash
      });
      if (status == 0) {
        this.detail = data;
        this.file = data.file;
        this.owner = data.owner;
        this.op_type = data.op_type;
        this.showError = false;
        this.receive_code = data.receive_code;
        this.status = data.status;
        this.status_name = data.status_name;
        this.button_info = data.button_info;
        this.stock_hash_copy = data.stock_hash; // 重新给stock_hash赋值
      } else if (status == '999999') {
        this.showError = true;
        this.message = "网络错误";
      } else {
        this.message = message || "什么都没有哦";
        this.showError = true;
      }
    },
    // 去赠送
    goToGive() {
      if (this.platform == 'mina') {
        mdToast("您可在摩点App中进行赠送哦");
      } else {
        // eslint-disable-next-line
        graft.app({
          functionName: "openController",
          functionParams: `md://nft_detail?stock_hash=${this.stock_hash}`,
          webFun: () => {
            location.href = this.linkUrl;
          }
        });
      }
    },
    async goToLink() {
      const res = await ntfReceive({
        stock_hash: this.stock_hash,
        receive_code: this.receive_code
      });
      if (res.status === 0) {
        mdToast("领取成功");
        this.getDetail('again');
      } else {
        mdToast(res.message);
      }
    },
    // 校验是否进行了个人认证
    goToCheck: throttle(async function () {
      const {
        status,
        message
      } = await authCheck();
      // 0-成功，500007-未认证，500008-未满18，500009-非大陆居民，500010-非大陆居民且未满18，500013-认证查询失败，
      if (status === 0) {
        this.goToLink();
      } else if (status === 500007) {
        showDialog({
          message: message,
          confirmButtonColor: '#00cba6'
        }).then(() => {
          const url = domain.wap + "/identity/verify";
          MDRouter.Link(url);
        });
      } else {
        mdToast(message);
      }
    })
  }
};